@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
$font-family-sans-serif: 'Source Sans 3', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$primary:       #a62d23;
$secondary:     #575481;
$success:       #e46b25;
@import "~bootstrap/scss/bootstrap.scss";

@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';

$ms-1: 0.75em;
$ms0: 1em;
$ms1: 1.333em;
$ms2: 1.777em;
$ms3: 2.369em;


.bg-light {
  background-color: transparent !important;
}

.back-stretch { 
  background: no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // background-color: red;
}

.flex-parent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

/*
.flex-child {
  // flex-grow: 1;
  // flex-shrink: 0;
  // flex-basis: auto;
}
*/

.flex-child-fill {
  // @extend .flex-child;
  flex-grow: 1;
}
.navbar-toggler {
  @include media-breakpoint-up(md) {
    margin-top: -10px;
  }
}
.address {
  font-size: $ms-1;
  padding-left: 1em;
  padding-top: 1em;
  // display: none;
  @include media-breakpoint-up(md) {
    font-size: $ms0;
    line-height: 1.4em;
    padding-left: 1em;
    padding-top: 0;
    display: block;
  }
}

.home {
  img {
    max-width: 100%;
  }
  .banner-background {
    @extend .back-stretch;
  }
  .banner-inverse-logo-row {
    @extend .flex-parent;
  }

  /*
  .banner-logo {
    // @extend .flex-child;
  }
  */

  .banner-logo-fill {
    @extend .flex-child-fill;
  }

  .banner-content {
    padding-top: 12em;
    padding-bottom: 8em;
    @include media-breakpoint-up(md) {
      padding-top: 14em;
      padding-bottom: 14em;
    }
    h2 {
      font-size: $ms1;
      text-align: center;
      font-weight: bold;
      margin-top: .5em;
    }
  }
  .services {
    padding-top: 3em;
    padding-bottom: 3em;
    text-align: center;
    background-repeat: repeat-x;
    background-position: center bottom;
    h2 {
      font-size: $ms1;
      font-weight: bold;
      text-transform: uppercase;
    }
    .service-list {
      padding-top: 2em;
      padding-bottom: 1em;
    }
    ul {
      @extend .list-unstyled;
    }
  }
  .about {
    // background-color: #575481;
    h2 {
      font-size: $ms2;
      font-weight: bold;
    }
    .about-image {
      @extend .back-stretch;
    }
    .about-content {
      padding: 3em 3em 2em;
      color: #fff;
    }
    a {
      color: #fff;
    }
  }

  .team {
    h2 {
      font-size: $ms3;
      font-weight: bold;
    }
    padding-top: 3em;
    padding-bottom: 2em;
    // background-color: #ccc;
    text-align: center;
    .staff-name {
      font-size: $ms1;
      font-weight: bold;
    }
    img {
      border-radius: 50%;
      max-width: 100%;
    }
    .staff-col {
      margin-bottom: 2em;
      flex: 1 0 auto;
    }
    .staff2-row {
      margin-bottom: 1em;
    }
  }
}

.contact {
  > .btn-primary {
    background-color: $white;
    color: $secondary;
    border-width: 0px;
    border-radius: 0px;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-weight: bold;
  }
  .navbar-brand img {
    width: 150px;
  }
}

.form-reset {
  textarea,
  label,
  input[type=text],
  input[type=email] {
    display: block;
    width: 100%;
    border-radius: 0px;
  }
  textarea,
  input {
    border: 1px solid $gray-400;
  }
  form button {
    border-width: 0;
    padding: .5em 1em;
  }
  .checkbox-wrapper {
    margin-left: 1.4em;
    input[type=checkbox] {
      margin-left: -1.4em;
      margin-right: .5em;
    }
  }
}

.order-modal,
.contact-modal {
  @extend .form-reset;
  .order-group {
    margin-bottom: 1em;
  }
}

.bio-modal {
  img {
    border-radius: 50%;
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }
}

.as-button {
  @extend .btn;
  @extend .btn-primary;
}
.order {
  .btn-primary {
    border-radius: 0px !important;
    font-size: $ms1;
    font-weight: 600;
  }
  .action-buttons {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
  }
}
.bio button {
  border-width: 0;
  background-color: transparent;
}
